var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { forwardRef, useMemo, } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as CheckmarkIcon } from '@icons/wolfkit-solid/checkmark-without-check-circle-solid.svg';
import { ReactComponent as XmarkIcon } from '@icons/wolfkit-solid/xmark-without-cross-circle-solid.svg';
import { ContainerColumn } from '@components/styled';
import { BodySemiBold } from '@components/styled/Typography';
import Icon from '@shared/ui/icons/Icon';
import InputMenuComponent from '@shared/ui/inputs/InputMenu';
const SearchInputContainer = styled(ContainerColumn)(() => ({
    width: 'unset',
    height: 'auto',
}));
const InputMenu = styled(InputMenuComponent)(props => ({
    '& .MuiInputBase-root': {
        paddingLeft: props.theme.spacing_sizes.xs * 1.25,
        paddingRight: props.theme.spacing_sizes.m,
    },
    '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
    },
    '& .MuiInputAdornment-positionStart p': {
        fontSize: 20,
        height: 22,
    },
}));
const Message = styled.div(props => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: `${props.theme.spacing_sizes.xs}px`,
    gap: `${props.theme.spacing_sizes.xs}px`,
    color: props.state === 'success' ? props.theme.palette.success.main : props.theme.palette.secondary.main,
}));
const MessageText = styled(BodySemiBold)(() => ({
    lineHeight: '18px',
}));
const CircularProgressStyled = styled(CircularProgress)(props => ({
    color: props.theme.palette.text.secondary,
}));
const SearchInputComponent = (_a, outerRef) => {
    var { startAdornment: startAdornmentComponent = undefined, searchFn, onChange = undefined, state = 'idle', message = undefined, notificationStatus = undefined, notification = undefined, menuItems = [] } = _a, props = __rest(_a, ["startAdornment", "searchFn", "onChange", "state", "message", "notificationStatus", "notification", "menuItems"]);
    const theme = useTheme();
    const startAdornment = useMemo(() => {
        if (startAdornmentComponent) {
            return (_jsx(InputAdornment, { position: 'start', children: startAdornmentComponent }));
        }
        return undefined;
    }, [startAdornmentComponent]);
    const endAdornment = useMemo(() => {
        if (state === 'loading') {
            return (_jsx(InputAdornment, { position: 'end', children: _jsx(CircularProgressStyled, { color: 'inherit', size: 20 }) }));
        }
        if (state === 'success' && !notification) {
            return (_jsx(InputAdornment, { position: 'end', children: _jsx(Icon, { size: 20, color: theme.palette.success.dark, IconComponent: CheckmarkIcon }) }));
        }
        if (state === 'error') {
            return (_jsx(InputAdornment, { position: 'end', children: _jsx(Icon, { size: 20, color: theme.palette.secondary.dark, IconComponent: XmarkIcon }) }));
        }
        return undefined;
    }, [state, notification, theme]);
    const handleChange = (ev) => {
        if (onChange) {
            onChange(ev);
        }
        searchFn(ev);
    };
    return (_jsxs(SearchInputContainer, { children: [_jsx(InputMenu, Object.assign({}, props, { onChange: handleChange, ref: outerRef, startAdornment: startAdornment, endAdornment: endAdornment, notificationStatus: notificationStatus || state, error: state === 'error', menuItems: menuItems })), (!notification &&
                (state === 'error' || state === 'success')) &&
                !!message && (_jsx(Message, { state: state, children: _jsx(MessageText, { children: message }) }))] }));
};
/**
 * wrapper above InputMenu with search status display
 */
const SearchInput = forwardRef(SearchInputComponent);
export default SearchInput;
